import React from 'react';
import { File } from '@model/generated/index';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '@components/global/Layout';
import { RouteEnum } from '@model/route.enum';
import { Meta } from '@components/global/Meta';

type DataProps = { logoMussenSL: File; logoKSB: File };

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    logoMussenSL: file(relativePath: { eq: "logo-museen-sl.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    logoKSB: file(relativePath: { eq: "logo-ksb.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout
      title="Wunderkammer"
      navigationItems={[
        { title: 'Projekt', to: RouteEnum.INFO },
        { title: 'Termine', to: RouteEnum.EVENT },
        { title: 'Förderung', to: RouteEnum.SPONSORD },
      ]}
    >
      <div className="flex">
        <div className="flex-auto">
          <div className="flex flex-col">
            <div className="m-32 w-3/4 bg-white">
              <GatsbyImage
                className="m-16"
                objectFit="cover"
                image={data.logoMussenSL.childImageSharp.gatsbyImageData}
                alt="Museen der Stadt Ludenscheid"
              />
            </div>
            <a
              href="https://www.kulturstiftung-des-bundes.de/dive-in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                className="self-end m-32 w-3/4"
                objectFit="contain"
                image={data.logoKSB.childImageSharp.gatsbyImageData}
                alt="Kulturstiftung des Bundes"
              />
            </a>
            <div className="flex-1 p-32">
              <h3>Wunderkammer der Zukunft</h3>
              <p>
                Wir freuen uns über die Förderung durch die Kulturstiftung des
                Bundes und die Museen der Stadt Lüdenscheid.
              </p>
              <p className="font-bold pb-16">
                Vielen Dank!
                <br />
                Das Team der Wunderkammer
              </p>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Förderung</h1>
      </div>
    </Layout>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Page;
